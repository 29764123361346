'use client';

import { usePostsContext } from '../context/posts-context';
import { Icon } from '@/components/icon';
import Select, { components } from 'react-select';

export function PostsFilter({ filter }) {
    if (!filter) return;
    const { handleFilterChange, currentFilter, availableTags, availableYears } =
        usePostsContext();

    let availableOptions = [];
    if (filter.type === 'tag') {
        availableOptions = filter.options.filter((option) =>
            availableTags.some(
                (tag) =>
                    tag.tag === option.value && tag.category === filter.category
            )
        );
    }
    if (filter.type === 'date') {
        availableOptions = filter.options.filter((option) => {
            return availableYears.some((year) => year === option.value);
        });
    }

    const selectedValues = currentFilter[filter.category]
        ? currentFilter[filter.category].split(',').map((value) => ({
              label: value,
              value: value,
          }))
        : [];

    return (
        <div key={filter.id}>
            {filter.label}
            <Select
                placeholder={filter.placeholder}
                onChange={(selectedOption) => {
                    handleFilterChange(selectedOption, filter.category);
                }}
                className="select posts__filter"
                classNamePrefix="select"
                instanceId="long-value-select"
                isClearable
                options={availableOptions}
                isMulti
                id={filter.id}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                selected={filter.selected}
                components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                    DropdownIndicator,
                    ClearIndicator,
                }}
                value={selectedValues}
            />
        </div>
    );
}

const MultiValue = (props) => {
    const value = props.getValue();

    if (value.length > 1) {
        return null;
    }
    return <components.MultiValue {...props} />;
};

const ValueContainer = ({ children, ...props }) => {
    const selectedValues = props.getValue();
    const displayLabel =
        selectedValues.length > 1
            ? `+ ${selectedValues.length} ${props.selectProps.selected}`
            : null;

    return (
        <components.ValueContainer {...props}>
            {children[0]} {/* Input */}
            {displayLabel && <span>{displayLabel}</span>}
            {children[1]} {/* Dropdown */}
        </components.ValueContainer>
    );
};

function Option(props) {
    const handleChange = () => {
        props.selectOption(props.data);
    };

    return (
        <components.Option {...props}>
            <input
                className="mr-3"
                type="checkbox"
                checked={props.isSelected}
                onChange={handleChange}
            />
            {props.label}
        </components.Option>
    );
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon={'fa-solid fa-chevron-down'} />
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Icon icon={'fa-solid fa-xmark'} />
        </components.ClearIndicator>
    );
};
