'use client';

import { ThreeDViewer1a } from '@/blocks/three-d-viewer/variants/three-d-viewer-1a';

const prefix = 'three_d';

const variants = {
    [`${prefix}_1a`]: ThreeDViewer1a,
};

export const ThreeDViewer = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
