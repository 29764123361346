'use client';
import { Media } from '@/components/media';
import { Button } from '@/components/button';
import { usePostsContext } from '../context/posts-context';
import classNames from 'classnames';
import parse from 'html-react-parser';

export function PostsListing1a() {
    const { posts, showTags, showDate } = usePostsContext();
    if (!posts.length) return;
    return (
        <div className="posts__listing posts__listing--1a">
            {posts?.map((post) => (
                <div key={post.id} className="post">
                    {post?.media && (
                        <div className="post__media">
                            <Media media={post.media} />
                        </div>
                    )}
                    <div className="post__content">
                        {showDate && (
                            <div className="post__date">{post.date}</div>
                        )}
                        {post.title && (
                            <div className="post__title">
                                {parse(post.title)}
                            </div>
                        )}
                        {post.description.value && (
                            <div
                                className={classNames('post__description', {
                                    table: post.description.value.includes(
                                        '<table>'
                                    ),
                                })}
                            >
                                {parse(post.description.value)}
                            </div>
                        )}
                        {showTags && (
                            <div className="post__tags">
                                {post.tags.map((tag) => (
                                    <div key={tag.id} className="post__tag">
                                        {tag.tag}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="post__links">
                            {post.links.map((link) => (
                                <Button
                                    key={link.id}
                                    className="post__link"
                                    {...link}
                                    btn_size={'md'}
                                    disable_icon
                                >
                                    {link.cta_text}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
