'use client';

import { Icon } from '@/components/icon';
import { useState, useRef, useEffect } from 'react';
import { useExpand } from '@/lib/hooks/use-expand';
import classNames from 'classnames';

export const DisclaimerTrigger = ({ title, hideTrigger, children }) => {
    const [hidden, setHidden] = useState(false);
    const trigger = useRef(null);
    const content = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            return entries[0].isIntersecting ||
                entries[0].boundingClientRect.top <= 0
                ? setHidden(true)
                : setHidden(false);
        });
        observer.observe(content.current);
        return () => {
            observer.disconnect();
        };
    }, []);
    const { expand } = useExpand();
    return (
        <>
            {!hideTrigger && !expand && (
                <a
                    href="#disclaimer"
                    className={classNames('disclaimer__trigger', {
                        'disclaimer__trigger--hidden': hidden,
                    })}
                    ref={trigger}
                >
                    <div className="disclaimer__trigger-wrap">
                        <div className="disclaimer__link">{title}</div>
                        <Icon
                            className="disclaimer__arrow"
                            icon={'fa-solid fa-angle-down'}
                        />
                    </div>
                </a>
            )}
            <div className="container disclaimer__container">
                <div
                    className="disclaimer__content"
                    id="disclaimer"
                    ref={content}
                >
                    {children}
                </div>
            </div>
        </>
    );
};
