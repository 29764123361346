'use client';

import { usePostsContext } from '../context/posts-context';
import { Pagination } from '@/components/pagination';

export function PostsPagination() {
    const { totalPages, handlePageChange, posts, currentPage } =
        usePostsContext();
    if (totalPages === 1 || !posts.length) return null;
    return (
        <Pagination
            pages={totalPages}
            onChangePage={handlePageChange}
            initialPage={currentPage}
        />
    );
}
