import { useState } from 'react';

export const useToggleHeart = (buttons, api) => {
    const [heart, setHeart] = useState(true);
    const toggleHeart = () => {
        const conditions = ['heart', 'aortic', 'atrium', 'aorta'];
        if (heart) {
            buttons.forEach((item) => {
                if (
                    item.name &&
                    conditions.some((el) =>
                        item.name.toLowerCase().includes(el)
                    )
                ) {
                    setHeart(false);
                    api.hide(item.id);
                }
            });
        }
        if (!heart) {
            buttons.forEach((item) => {
                if (
                    item.name &&
                    conditions.some((el) =>
                        item.name.toLowerCase().includes(el)
                    )
                ) {
                    setHeart(true);
                    api.show(item.id);
                }
            });
        }
    };
    return { heart, toggleHeart };
};
