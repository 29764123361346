import { useRef } from 'react';
import { Button } from '@/components/button';
import { useToggleDevice } from '../hooks/use-toggle-device';
import { useToggleHeart } from '../hooks/use-toggle-heart';
import { useSketchFab } from '../hooks/use-sketch-fab';
import { resetView } from '../utils/reset-view';
import classNames from 'classnames';
import Script from 'next/script';
// import useToggleItems from '../hooks/useToggleItems'; // used for testing

export const ThreeDViewer1a = ({
    settings,
    model_id,
    start_player,
    loading_player,
    pause_player,
    hide_heart,
    show_heart,
    hide_device,
    show_device,
    show_surgeon_view,
    attr,
}) => {
    const ref = useRef(null);
    const { buttons, setButtons, api, position, target, playing } =
        useSketchFab(model_id, ref);
    const { heart, toggleHeart } = useToggleHeart(buttons, api);
    const { device, toggleDevice } = useToggleDevice(buttons, api);
    // const { toggleItem } = useToggleItems(api, buttons, setButtons); // used for testing

    return (
        <>
            <Script src="https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js" />
            <section
                className={classNames('section', {
                    ...settings,
                })}
                {...attr}
            >
                <div className="container">
                    <div className="btn-group mb-4">
                        {!playing && (
                            <Button
                                icon={'fa-solid fa-play'}
                                icon_position={'right'}
                                trigger={() => {
                                    if (api) api.start();
                                }}
                            >
                                {api ? start_player : loading_player}
                            </Button>
                        )}
                        {playing && (
                            <>
                                <Button
                                    icon={'fa-solid fa-pause'}
                                    icon_position={'right'}
                                    trigger={() => api.stop()}
                                >
                                    {pause_player}
                                </Button>

                                <Button
                                    btn_style={heart ? 'tertiary' : 'secondary'}
                                    trigger={toggleHeart}
                                >
                                    {heart ? hide_heart : show_heart}
                                </Button>
                                <Button
                                    btn_style={
                                        device ? 'tertiary' : 'secondary'
                                    }
                                    trigger={toggleDevice}
                                >
                                    {device ? hide_device : show_device}
                                </Button>
                                <Button
                                    trigger={() =>
                                        resetView(api, position, target)
                                    }
                                    btn_style="ghost"
                                >
                                    {show_surgeon_view}
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="video">
                        <iframe
                            className="video__frame"
                            ref={ref}
                            title={'3D animation'}
                            frameBorder="0"
                            loading="lazy"
                        />
                    </div>

                    {/* Used for testing purposes to see all id's associated with 3d model. */}
                    {/* {buttons &&
					buttons.map(button => {
						return (
							<button onClick={() => toggleItem(button)}>
								{button.id} - {button.name}
							</button>
						);
					})} */}
                </div>
            </section>
        </>
    );
};
