'use client';

import { Media } from '@/components/media';
import { Link } from '@/components/link';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Toggle = ({ button, active_media, inactive_media }) => {
    const pathname = usePathname();
    const page = pathname || window.location.href;
    const handleClick = (e) => {
        if (`/${button.cta_link}` === page) {
            e.preventDefault();
        }
    };
    return (
        <>
            {button && (
                <Link
                    href={`/${button.cta_link}`}
                    className={classNames('toggle', {
                        'toggle--active': `/${button.cta_link}` === page,
                        'toggle--inactive': `/${button.cta_link}` !== page,
                    })}
                    onClick={handleClick}
                    tabIndex={`/${button.cta_link}` === page ? -1 : undefined}
                >
                    <Media
                        wrapperClassName="toggle__media"
                        media={
                            `/${button.cta_link}` === page
                                ? active_media
                                : inactive_media
                        }
                        disableCaption
                        sizes={getSizes({
                            desktop: 28,
                            tablet: 47,
                        })}
                    />
                </Link>
            )}
        </>
    );
};
