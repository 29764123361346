'use client';

import { Button } from '@/components/button';
import { usePostsContext } from '../context/posts-context';
import parse from 'html-react-parser';

export function PostsListing2a() {
    const { posts, showTags, showDate } = usePostsContext();
    if (!posts.length) return;
    return (
        <div className="posts__listing posts__listing--2a">
            {posts?.map((post) => (
                <div key={post.id} className="post">
                    <div className="post__content-wrapper">
                        <div className="post__content">
                            <div>
                                {showDate && (
                                    <div className="post__date">
                                        {post.date}
                                    </div>
                                )}
                                {post.title && (
                                    <div className="post__title">
                                        {parse(post.title)}
                                    </div>
                                )}
                            </div>
                            {post.description.value && (
                                <div className="post__description">
                                    {parse(post.description.value)}
                                </div>
                            )}
                        </div>
                        {showTags && (
                            <div className="post__tags">
                                {post.tags.map((tag) => (
                                    <div key={tag.id} className="post__tag">
                                        {tag.tag}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="post__links">
                        {post.links.map((link) => (
                            <Button
                                key={link.id}
                                className="post__link"
                                {...link}
                                btn_size={'md'}
                                disable_icon
                            >
                                {link.cta_text}
                            </Button>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
