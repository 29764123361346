'use client';

import { Button } from '@/components/button';
import { usePostsContext } from '../context/posts-context';
import { Media } from '@/components/media';
import { Link } from '@/components/link';
import parse from 'html-react-parser';

export function PostsListing3a() {
    const { posts, showTags, showDate } = usePostsContext();
    if (!posts.length) return;
    return (
        <div className="posts__listing posts__listing--3a">
            {posts?.map((post) => {
                const numOfLinks = post.links.length;
                return (
                    <PostParent key={post.id} post={post}>
                        {post?.media && (
                            <Media
                                wrapperClassName="post__media"
                                fill
                                objectFit="cover"
                                media={post.media}
                            />
                        )}

                        <div className="post__content">
                            {showDate && (
                                <div className="post__date">{post.date}</div>
                            )}
                            {post.title && (
                                <div className="post__title">
                                    {parse(post.title)}
                                </div>
                            )}
                            {post.description.value && (
                                <div className="post__description">
                                    {parse(post.description.value)}
                                </div>
                            )}
                            {showTags && (
                                <div className="post__tags">
                                    {post.tags.map((tag) => (
                                        <div key={tag.id} className="post__tag">
                                            {tag.tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="post__links">
                                {numOfLinks === 1 ? (
                                    <Button
                                        key={post.links[0].id}
                                        className="post__link"
                                        {...post.links[0]}
                                        cta_link={null}
                                        element_type="div"
                                        btn_size={'md'}
                                        disable_icon
                                    >
                                        {post.links[0].cta_text}
                                    </Button>
                                ) : (
                                    <>
                                        {post.links.map((link) => (
                                            <Button
                                                key={link.id}
                                                className="post__link"
                                                {...link}
                                                btn_size={'md'}
                                                disable_icon
                                            >
                                                {link.cta_text}
                                            </Button>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </PostParent>
                );
            })}
        </div>
    );
}

function PostParent({ children, post }) {
    const numOfLinks = post.links.length;

    if (numOfLinks === 1) {
        return (
            <Link href={post.links[0].cta_link} className="post post--link">
                {children}
            </Link>
        );
    } else {
        return <div className="post">{children}</div>;
    }
}
