'use client';

import { createContext, useCallback, useContext, useRef, useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import parse from 'html-react-parser';
import { Suspense } from 'react';

const PostsContext = createContext();

const usePostsContext = () => {
    const context = useContext(PostsContext);
    if (!context) {
        throw new Error('No Context Found');
    }
    return context;
};

const PostsProvider = ({ ...props }) => {
    return (
        <Suspense>
            <SuspendedPostsProvider {...props} />
        </Suspense>
    );
};

const SuspendedPostsProvider = ({
    children,
    posts,
    itemsPerPage,
    filters,
    pathname,
    search,
    showTags,
    showDate,
    clear_filters,
    sorting,
    no_results,
}) => {
    const searchParams = useSearchParams();
    const params = new URLSearchParams(searchParams.toString());
    params.delete('slug');
    const query = {};
    params.forEach((value, key) => {
        query[key] = value;
    });
    const filter = query;
    const sortBy = query.sortBy || null;
    const currentSearch = query.q || '';
    const currentPage = Number(query.page) || 1;

    const ref = useRef();

    function removeDuplicates(arr) {
        const uniqueValues = new Set();
        return arr.filter((obj) => {
            if (!uniqueValues.has(obj.tag)) {
                uniqueValues.add(obj.tag);
                return true;
            }
            return false;
        });
    }

    function removeYearDuplicates(arr) {
        const uniqueValues = new Set();
        return arr.filter((val) => {
            if (!uniqueValues.has(val)) {
                uniqueValues.add(val);
                return true;
            }
            return false;
        });
    }

    function sanitize(value) {
        return value.toLowerCase().replace(/\s/g, '');
    }

    const filteredPosts = posts.filter((post) => {
        for (const key of Object.keys(filter)) {
            if (!filter[key] || key === 'page' || key === 'sortBy') continue;

            if (key === 'year' && !filter.year.includes(post.year))
                return false;

            if (key === 'q') {
                const searchTerm = sanitize(filter.q);
                if (
                    (search.filter.includes('title') &&
                        post.title &&
                        sanitize(post.title).includes(searchTerm)) ||
                    (search.filter.includes('description') &&
                        post.description.value &&
                        sanitize(post.description.value).includes(
                            searchTerm
                        )) ||
                    (search.filter.includes('date') &&
                        post.date &&
                        sanitize(post.date).includes(searchTerm)) ||
                    (search.filter.includes('tag') &&
                        post.tags.some((tag) =>
                            sanitize(tag.tag).includes(searchTerm)
                        ))
                ) {
                    return true;
                }
                return false;
            }

            const filterValues = filter[key].split(',');
            if (!post.tags.some((tag) => filterValues.includes(tag.tag)))
                return false;
        }
        return true;
    });

    const sortedPosts = [...filteredPosts].sort((a, b) => {
        switch (sortBy) {
            case 'Featured':
                return b.featured - a.featured;
            case 'A-Z':
                return a.title.localeCompare(b.title);
            case 'Z-A':
                return b.title.localeCompare(a.title);
            case 'Oldest':
                return new Date(a.date) - new Date(b.date);
            case 'Latest':
                return new Date(b.date) - new Date(a.date);
            default:
                return 0;
        }
    });

    const availableTags = removeDuplicates(
        filteredPosts.map((post) => post.tags).flat()
    );

    const availableYears = removeYearDuplicates(posts.map((post) => post.year));

    const handleSearch = useCallback(
        (data) => {
            const newQuery = { ...query };
            const search = data.q?.trim();
            if (search) {
                newQuery.q = search;
            } else {
                delete newQuery.q;
            }

            delete newQuery.page;

            window.history.pushState(
                null,
                '',
                `${pathname}?${new URLSearchParams(newQuery)}`
            );
        },
        [query, pathname]
    );

    const totalPages = useMemo(
        () => Math.ceil(filteredPosts.length / itemsPerPage),
        [filteredPosts.length, itemsPerPage]
    );

    const paginatedPosts = useMemo(
        () =>
            sortedPosts.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            ),
        [sortedPosts, currentPage, itemsPerPage]
    );

    function handlePageChange(newPage) {
        if (newPage === 1) {
            delete query.page;
        } else {
            query.page = newPage;
        }

        window.history.pushState(
            null,
            '',
            `${pathname}?${new URLSearchParams(query)}`
        );
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    function handleFilterChange(selectedOption, filterKey) {
        delete query.page;
        if (!selectedOption || !selectedOption.length) {
            delete query[filterKey];
        } else {
            query[filterKey] = selectedOption
                ? selectedOption.map((option) => option.value).join(',')
                : '';
        }
        window.history.pushState(
            null,
            '',
            `${pathname}?${new URLSearchParams(query)}`
        );
    }

    function handleClearFilters() {
        window.history.pushState(null, '', `${pathname}`);
    }

    function handleSort(key) {
        delete query.page;
        query.sortBy = key;
        window.history.pushState(
            null,
            '',
            `${pathname}?${new URLSearchParams(query)}`
        );
    }

    function resetSearch() {
        delete query.q;
        window.history.pushState(
            null,
            '',
            `${pathname}?${new URLSearchParams(query)}`
        );
    }

    return (
        <PostsContext.Provider
            value={{
                handleClearFilters,
                handleFilterChange,
                handlePageChange,
                handleSearch,
                resetSearch,
                handleSort,
                currentSearch,
                search,
                posts: paginatedPosts,
                currentFilter: filter,
                filters,
                paginatedPosts,
                totalPages,
                availableTags,
                availableYears,
                currentPage,
                showTags,
                showDate,
                clear_filters,
                sortBy,
                sorting,
            }}
        >
            <div className="posts" ref={ref}>
                {children}
                {!filteredPosts.length && no_results && parse(no_results.value)}
            </div>
        </PostsContext.Provider>
    );
};

export { PostsProvider, usePostsContext };
