'use client';

import { usePostsContext } from '../context/posts-context';
import { useForm } from 'react-hook-form';
import { Icon } from '@/components/icon';
import { Button } from '@/components/button';
import { useEffect } from 'react';

export function PostsSearch() {
    const { search, handleSearch, resetSearch, currentSearch } =
        usePostsContext();
    const { register, handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            q: currentSearch,
        },
    });
    const hasSearch = watch('q');

    if (!search) return null;

    function handleClearSearch() {
        setValue('q', '');
        resetSearch();
    }

    useEffect(() => {
        if (!currentSearch) {
            setValue('q', '');
        }
        if (currentSearch) {
            setValue('q', currentSearch);
        }
    }, [currentSearch]);

    function handleKeyDown(event) {
        if (event.key === 'Escape') {
            handleClearSearch();
        }
    }

    return (
        <form
            className="form form--lg posts__search"
            onSubmit={handleSubmit(handleSearch)}
        >
            <div className={'posts__searchbar'}>
                <label className="form__label">
                    {search.label}
                    <div className="form__validation">
                        <input
                            type="text"
                            placeholder={search.placeholder}
                            className={'form__input form__input--search'}
                            {...register('q')}
                            onKeyDown={handleKeyDown}
                        />
                        {hasSearch && (
                            <button
                                className="posts__clear"
                                onClick={handleClearSearch}
                                type="button"
                            >
                                <Icon icon={'fa-solid fa-close'} />
                            </button>
                        )}
                        <Icon
                            icon={'fa-solid fa-magnifying-glass'}
                            className="form__search"
                        />
                    </div>
                </label>
            </div>
            <Button className="post__submit" btn_size="lg" variant="primary">
                Submit
            </Button>
        </form>
    );
}
