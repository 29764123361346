import { useEffect, useState } from 'react';
const getData = (items) => {
    const list = [];
    const formatData = (items) => {
        items.forEach((item) => {
            list.push({ id: item.instanceID, name: item.name, hidden: false });
            if (item.children) {
                formatData(item.children);
            }
        });
    };
    formatData(items.children);
    return list;
};

export const useSketchFab = (id, ref, start) => {
    const [buttons, setButtons] = useState(null);
    const [api, setApi] = useState(null);
    const [position, setPosition] = useState(null);
    const [target, setTarget] = useState(null);
    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        // Initialize the viewer
        setTimeout(() => {
            let client = new window.Sketchfab(ref.current);
            client.init(id, {
                success: function onSuccess(api) {
                    api.addEventListener('viewerready', function () {
                        setPlaying(true);
                        api.getSceneGraph(function (err, result) {
                            if (err) {
                                return;
                            } // get the id from that log
                            const data = getData(result);
                            setButtons(data);
                        });
                        setTimeout(() => {
                            api.getCameraLookAt(function (err, camera) {
                                const { position, target } = camera;
                                setPosition(position);
                                setTarget(target);
                            });
                        }, 2500);
                    });
                    setApi(api);
                    api.addEventListener('viewerstart', () => {
                        setPlaying(true);
                    });
                    api.addEventListener('viewerstop', () => {
                        setPlaying(false);
                    });
                },
            });
        }, 2000);
    }, [id, ref, start]);
    return { buttons, setButtons, api, position, target, playing };
};
