'use client';

import { usePostsContext } from '../context/posts-context';
import { PostsFilter } from './posts-filter';
import { Dropdown } from '@/components/dropdown';

export const PostsFilters = () => {
    const {
        filters,
        handleClearFilters,
        handleSort,
        clear_filters,
        sortBy,
        sorting,
    } = usePostsContext();
    if (!filters?.length) return null;

    return (
        <div className="form form--lg posts__filters">
            {filters.map((filter) => (
                <PostsFilter key={filter?.id} filter={filter} />
            ))}
            <div className="posts__controls">
                {clear_filters && (
                    <button
                        className="post__clear"
                        onClick={handleClearFilters}
                    >
                        {clear_filters}
                    </button>
                )}
                {sorting && (
                    <Dropdown
                        onSelectChange={handleSort}
                        className="posts__sort"
                        selected={sortBy || sorting.latest}
                        data={[
                            sorting.latest,
                            sorting.oldest,
                            sorting.featured,
                            sorting['a-z'],
                            sorting['z-a'],
                        ]}
                    />
                )}
            </div>
        </div>
    );
};
