import { useState } from 'react';

export const useToggleDevice = (buttons, api) => {
    const [device, setDevice] = useState(true);
    const conditions = [
        '246 - physioflex',
        '152 - cosgrove',
        '169 - physioii',
        '173 - physio1',
        '168 - imr',
        '323 - cosgrove_tricuspid',
        '245 - physiotricuspid',
        '145 - mc3',
    ];
    const toggleDevice = () => {
        if (device) {
            buttons.forEach((item) => {
                if (item.name) {
                    const val = `${item.id} - ${item.name.toLowerCase()}`;
                    if (conditions.some((el) => val.includes(el))) {
                        setDevice(false);
                        api.hide(item.id);
                    }
                }
            });
        }
        if (!device) {
            buttons.forEach((item) => {
                if (item.name) {
                    const val = `${item.id} - ${item.name.toLowerCase()}`;
                    if (conditions.some((el) => val.includes(el))) {
                        setDevice(true);
                        api.show(item.id);
                    }
                }
            });
        }
    };

    return { device, toggleDevice };
};
